import { ChakraProvider, extendTheme, CSSReset } from '@chakra-ui/react';
import { Hydrate, QueryClient, QueryClientProvider, QueryCache } from 'react-query';
import { appWithTranslation } from 'next-i18next';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useEffect, useState } from 'react';
import Head from 'next/head';
import posthog from 'posthog-js';

import type { AppProps } from 'next/app';

import '../styles/OverlaySpinner.css';
import '../styles/global.css';

const colors = {
  customs: {
    blue: {
      500: '#7866FF',
    },
    pink: {
      500: '#FFB1AE',
    },
  },
};

const theme = extendTheme({
  colors,
  styles: {
    global: () => ({
      body: {
        bg: '#f1f1f1',
      },
    }),
  },
});

function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    posthog.init(
      process.env.NEXT_PUBLIC_POSTHOG_API_KEY as string,
      {
        api_host: 'https://app.posthog.com',
        capture_pageview: true,
        autocapture: true,
      },
      'FORM',
    );
  }, []);

  /**
   * Create a new QueryClient instance inside of your app, and on an instance ref (or in React state).
   * This ensures that data is not shared between different users and requests,
   * while still only creating the QueryClient once per component lifecycle
   *
   * cf: https://react-query.tanstack.com/guides/ssr
   */
  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache(),
      }),
  );

  return (
    <>
      <Head>
        <title>Just Checkout</title>
        <meta name="description" content="Just 1-click checkout" />
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, user-scalable=no" />
      </Head>
      <CSSReset />
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <ChakraProvider resetCSS theme={theme}>
            {/* @ts-expect-error typescript is in vacation */}
            <Component {...pageProps} />
            <ReactQueryDevtools initialIsOpen={false} />
          </ChakraProvider>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
}

export default appWithTranslation(App);
